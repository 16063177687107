import { render, staticRenderFns } from "./Chan.vue?vue&type=template&id=8280c2ac&scoped=true&"
import script from "./Chan.vue?vue&type=script&lang=js&"
export * from "./Chan.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8280c2ac",
  null
  
)

export default component.exports